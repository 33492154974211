import React, { memo, useEffect } from 'react'
import { useFela } from '@fortressiq/fiq-ds'
import { InfiniteLoader } from 'react-virtualized'

import uiStore from 'lib/UiStore'
import localizer from 'lib/Localizer'
import PanelMetrics from 'components/PanelMetrics/PanelMetrics'
import VirtualizedTable from 'components/table/VirtualizedTable'

import { useEventsDispatch } from './EventContext'
import { getColumns } from './EventColumns'
import { eventListContainerCSS, panelMetricsContainer, tableContainer } from './styles'

const isLite = uiStore?.tenant?.isLite
const columns = getColumns(isLite)

const EventList = ({
  events,
  eventCount,
  loadMoreEvents,
  selectedEventIndex,
  setSelectedEventIndex,
  setVisibleCols,
  searchAfter = [],
}) => {
  const eventsDispatch = useEventsDispatch()
  const { css } = useFela()

  useEffect(() => {
    eventsDispatch({
      type: 'set-export-columns',
      columns: columns.filter(c => c.visible).map(vc => vc.dataKey),
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (eventCount === 0) return null

  const isRowLoaded = ({ index }) => !!events[index]

  const rowGetter = ({ index }) => events[index] || {}

  const onRowClick = ({ index }) => setSelectedEventIndex(index)

  const setVisibleColumns = visibleColumns => {
    const cols = visibleColumns
    setVisibleCols(cols)
    eventsDispatch({
      type: 'set-export-columns',
      columns: cols,
    })
  }

  return (
    <div className={css(eventListContainerCSS)}>
      <div className={css(panelMetricsContainer)}>
        <PanelMetrics metrics={[{ title: 'Events', value: localizer.asCount(eventCount) }]} />
      </div>
      {eventCount <= 10000 || searchAfter === undefined || searchAfter.length === 0 ? (
        <InfiniteLoader
          isRowLoaded={isRowLoaded}
          loadMoreRows={loadMoreEvents}
          rowCount={eventCount}
          minimumBatchSize={100}
        >
          {({ onRowsRendered, registerChild }) => (
            <VirtualizedTable
              selectedEventIndex={selectedEventIndex}
              scrollToIndex={selectedEventIndex}
              onRowsRendered={onRowsRendered}
              ref={registerChild}
              columns={columns}
              rowGetter={rowGetter}
              rowHeight={20}
              rowCount={eventCount}
              onRowClick={onRowClick}
              setVisibleColumns={setVisibleColumns}
              tableContainerClassName={css(tableContainer)}
            />
          )}
        </InfiniteLoader>
      ) : (
        <InfiniteLoader
          isRowLoaded={isRowLoaded}
          loadMoreRows={loadMoreEvents}
          rowCount={eventCount}
          minimumBatchSize={100}
        >
          {({ onRowsRendered, registerChild }) => (
            <VirtualizedTable
              selectedEventIndex={selectedEventIndex}
              scrollToIndex={events.length}
              onRowsRendered={onRowsRendered}
              ref={registerChild}
              columns={columns}
              rowGetter={rowGetter}
              rowHeight={20}
              rowCount={Math.max(events.length, 100)}
              onRowClick={onRowClick}
              setVisibleColumns={setVisibleColumns}
              tableContainerClassName={css(tableContainer)}
            />
          )}
        </InfiniteLoader>
      )}
    </div>
  )
}

export default memo(EventList)

/* eslint-disable react/state-in-constructor */
import React from 'react'
import { AutoSizer, Table, Column } from 'react-virtualized'
import { Dropdown, Icon, Menu, MenuItem, theme } from '@fortressiq/fiq-ds'
import pick from 'lodash/pick'

import css from './virtualizedTable.scss'

export default class VirtualizedTable extends React.Component {
  state = {
    menu: [],
    allColumns: [],
  }

  componentDidMount() {
    this.setColumnInfo()
  }

  componentDidUpdate(prevProps) {
    const { columns } = this.props
    if (columns === prevProps.columns) return
    this.setColumnInfo()
  }

  render() {
    const { menu, allColumns } = this.state
    const { tableContainerClassName = '', headerClassName, data, scrollToIndex } = this.props
    const visibleColumns = allColumns.filter(column => column.visible)

    const tableContainerClassnames = [css.table, tableContainerClassName].filter(Boolean)

    return (
      <div className={tableContainerClassnames.join(' ')}>
        <AutoSizer>
          {({ width, height }) => (
            <Table
              rowCount={data ? data.length : null}
              {...this.props}
              headerClassName={`${css.headerClass} ${headerClassName || ''}`}
              headerHeight={this.getRowHeight(true)}
              height={height}
              rowClassName={this.rowClassName}
              rowHeight={this.getRowHeight()}
              rowGetter={this.rowGetter}
              rowRenderer={rowRenderer}
              width={width}
              scrollToIndex={scrollToIndex}
              tabIndex={null}
            >
              {visibleColumns.map(column => (
                <Column
                  key={column.dataKey}
                  dataKey={column.dataKey}
                  disableSort={true}
                  cellRenderer={column.cellRenderer}
                  width={column.width}
                  flexGrow={1}
                  headerClassName={css.headerColumn}
                  headerRenderer={() => headerRenderer(column.header, column.headerRenderer)}
                />
              ))}
              <Column
                disableSort={true}
                dataKey=''
                flexGrow={1}
                width={50}
                headerClassName={css.headerColumn}
                headerRenderer={() => (
                  <Dropdown
                    layer={{ placement: 'bottom-end', triggerOffset: 8 }}
                    overlay={<Menu small={true}>{menu}</Menu>}
                    trigger={{ type: 'click' }}
                  >
                    <Icon className={css.settingsCog} icon='cog' />
                  </Dropdown>
                )}
              />
            </Table>
          )}
        </AutoSizer>
      </div>
    )
  }

  setColumnInfo() {
    const { columns } = this.props
    const menu = [
      ...columns.map((col, index) => {
        const className = col.columnType === VirtualizedTable.columnType.action ? css.heavyOption : ''
        const menuProps = {
          className: className,
          onClick: () => this.toggleColumn(col),
          suffixIcon: col.visible ? { fill: theme['success-color'], icon: 'check' } : undefined,
        }
        return (
          <MenuItem {...menuProps} key={index}>
            {col.menuName || col.header}
          </MenuItem>
        )
      }),
    ]

    const allColumns = columns.map((col, index) => {
      col.index = index
      return col
    })

    this.setState({
      menu,
      allColumns,
    })
  }

  getRowHeight = (isHeader = false) => {
    const { rowHeight } = this.props
    if (rowHeight && !isHeader) return rowHeight
    return 30
  }

  toggleColumn = column => {
    const { allColumns, menu: stateMenu } = this.state
    const { setVisibleColumns } = this.props
    const myallColumns = allColumns
    const menu = [...stateMenu]

    const columnValue = myallColumns.find(col => col.index === column.index)

    const className = columnValue.columnType === VirtualizedTable.columnType.action ? css.heavyOption : ''
    const menuProps = {
      className: className,
      key: columnValue.index,
      onClick: () => this.toggleColumn(columnValue),
      suffixIcon: !columnValue.visible ? { fill: theme['success-color'], icon: 'check' } : undefined,
    }
    menu[columnValue.index] = <MenuItem {...menuProps}>{columnValue.menuName || columnValue.header}</MenuItem>

    columnValue.visible = !columnValue.visible

    if (setVisibleColumns) {
      setVisibleColumns(myallColumns.filter(c => c.visible))
    }

    this.setState({
      menu,
      allColumns: myallColumns,
    })
  }

  rowClassName = rowInfo => {
    const { index } = rowInfo
    const { selectedEventIndex, data, rowClassName } = this.props

    let stripeClass = index % 2 === 0 ? css.even : css.odd

    //we want the header row not to be part of the zebra striping
    //this row is indexed at -1
    if (index === -1) stripeClass = css.headerRow

    const selectedClass = index === selectedEventIndex ? css.selected : ''

    const rowEvent = data && data[index]
    const markedClass = rowEvent && rowEvent.marked ? css.marked : ''

    return `${css.row} ${stripeClass} ${selectedClass || markedClass} ${rowClassName || ''}`
  }

  rowGetter = args => {
    const { data, rowGetter } = this.props
    if (rowGetter) {
      return rowGetter(args)
    }
    return data[args.index]
  }
}

const rowRenderer = args => {
  const { index, rowData, onRowClick, columns } = args
  const divProps = pick(args, ['className', 'key', 'style'])
  divProps['aria-rowindex'] = index + 1
  divProps['aria-label'] = 'row'
  divProps.tabIndex = -1

  if (onRowClick) {
    divProps.onClick = e => onRowClick({ e, index, rowData })
  }

  return (
    <div {...divProps} role='row'>
      {columns}
    </div>
  )
}

const headerRenderer = (header, customHeaderRenderer) => {
  if (customHeaderRenderer) return customHeaderRenderer(header)
  return header
}
// enum of column types -- currently only one type,
// but we want to make sure arbitrary values are not considered
VirtualizedTable.columnType = {
  action: 'ACTION',
}

import React, { useCallback, useState } from 'react'
import { Link } from 'react-router-dom'

import { Table, AdvancedCell, DateCell } from '@fortressiq/fiq-ds'
import { columnWidths } from 'components/TrinityTable'
import { useQueryParams, withDefault, NumberParam } from 'use-query-params'

import api from 'lib/Api'

const ScreenList = ({ appId, cycleId }) => {
  const [screens, setScreens] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [pageCount, setPageCount] = useState(100)
  const [total, setTotal] = useState(0)
  const [clientSidePagination, setClientSidePagination] = useState(false)

  const [persistedState, setPersistedState] = useQueryParams({
    pageSize: withDefault(NumberParam, 10),
    pageIndex: withDefault(NumberParam, 0),
  })

  const fetchData = useCallback(
    ({ pageSize, pageIndex }) => {
      const newState = {
        pageSize: pageSize,
        pageIndex: pageIndex,
      }
      if (JSON.stringify(persistedState) !== JSON.stringify(newState)) {
        setPersistedState(newState)
      }

      async function fetchingData() {
        setIsLoading(true)
        const { data } = await api.get(`/applications/${appId}/screens/cycle/${cycleId}`, {
          applicationUuid: appId,
          pageSize: pageSize,
          pageNumber: pageIndex,
        })
        setScreens(data.screens)
        setPageCount(Math.ceil(data.totalElements / pageSize))
        setTotal(data.totalElements)
        setIsLoading(false)
        if (pageSize < data.screens.length && data.screens.length === data.totalElements) {
          setClientSidePagination(true)
        }
      }
      fetchingData()
    },
    [appId, cycleId, persistedState]
  )

  const columns = [
    {
      Header: 'Provisional Signature',
      accessor: 'signature',
      width: 250,
      Cell: ({ value }) => (
        <AdvancedCell copyTextOverride={value}>
          {value ? (
            <Link to={`/applications/${appId}/screens/${encodeURIComponent(value)}/${cycleId}`}>{value}</Link>
          ) : (
            ''
          )}
        </AdvancedCell>
      ),
    },
    {
      Header: 'Name',
      accessor: 'name',
      Cell: ({ value }) => <AdvancedCell>{value}</AdvancedCell>,
    },
    {
      Header: 'Confidence',
      accessor: 'confidence',
      width: 100,
    },
    {
      Header: 'Event Count',
      accessor: 'count',
      width: 100,
    },
    {
      Header: 'Created',
      accessor: 'createdAt',
      width: columnWidths.date,
      Cell: ({ value }) => <DateCell timestamp={value} />,
    },
    {
      Header: 'Details',
      accessor: 'action',
      Cell: ({ value }) => <DateCell timestamp={value} />,
    },
  ]

  return clientSidePagination ? (
    <Table
      initialState={{ pageSize: 10 }}
      pageSizeOptions={[10, 20, 50, 100]}
      columns={columns}
      data={screens || []}
      loading={isLoading}
    />
  ) : (
    <Table
      initialState={{ pageSize: 10 }}
      pageSizeOptions={[5, 10, 20, 50, 100]}
      columns={columns}
      data={screens || []}
      fetchData={fetchData}
      loading={isLoading}
      pageCount={pageCount}
      disablePagination={false}
      disableSortBy={true}
      controlledTable={true}
      total={total}
    />
  )
}

export default ScreenList

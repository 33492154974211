// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`CreateTenantButton component should NOT render 1`] = `
<UserProvider
  init={
    {
      "roleNames": "limited",
    }
  }
>
  <CreateTenantButton>
    <Can
      perform="/admin/tenant/create"
    />
  </CreateTenantButton>
</UserProvider>
`;

exports[`CreateTenantButton component should render without throwing an error 1`] = `
<UserProvider
  init={
    {
      "roleNames": "superuser",
    }
  }
>
  <CreateTenantButton>
    <Can
      perform="/admin/tenant/create"
    >
      <Button
        href="/admin/tenants/new"
        type="secondary"
      >
        <a
          className="a b c d e f g h i j k l m n o p q r s t u v w x y z ab ac ae af ag ah ai aj ak al fiqds-button"
          disabled={false}
          href="/admin/tenants/new"
          onClick={[Function]}
          src=""
        >
          <Element
            as="span"
            className="ant-btn-txt fiqds-btn-txt"
            style={[Function]}
          >
            <Component
              as="span"
              className="ant-btn-txt fiqds-btn-txt"
              style={[Function]}
            >
              <Component>
                <span
                  className="ao ap r ant-btn-txt fiqds-btn-txt"
                >
                  Create Tenant
                </span>
              </Component>
            </Component>
          </Element>
        </a>
      </Button>
    </Can>
  </CreateTenantButton>
</UserProvider>
`;

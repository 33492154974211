import React, { Fragment, useEffect, useState } from 'react'
import { shape, string, number, bool, func, oneOfType } from 'prop-types'
import set from 'lodash/set'
import {
  Button,
  Col,
  Element,
  get,
  Group,
  Heading,
  Input,
  Label,
  Radio,
  RadioGroup,
  Row,
  Select,
  theme,
  Typography,
  useModal,
  useNotification,
  FormControl,
} from '@fortressiq/fiq-ds'

import Can, { roleActionCheck } from 'components/can/Can'
import { useUserState } from 'context/UserContext'

import api from 'lib/Api'
import uiStore from 'lib/UiStore'

import MainLoader from 'components/loaders/MainLoader'
import ConfigureObserver from 'components/configureObserver/ConfigureObserver'
import { LIST_CATEGORIES, LIST_TYPES } from 'components/configureObserver/Constants'
import { fetchUrlAllowAndBlockLists, getConfiguredAllowBlockLists } from 'components/configureObserver/utils'

import InfoTooltip from 'components/infoTooltip/InfoTooltip'
import SwitchSection from './SwitchSection'
import { columnCSS, containerCss, contentCss, footerCss, observerConfigContainer, coeErrorCss } from './styles/index'

import { AGGREGATION_STRATEGIES, validCoeUrl, TENANT_TYPES } from './constants'

const { Paragraph } = Typography

const fullRowInputContainerProps = { style: { margin: `0 ${theme['default-spacer-md']} 0 0` } }
const selectLabelStyles = {
  style: {
    marginTop: theme['default-spacer-md'],
  },
}

const sharedInputProps = {
  containerProps: {
    style: { width: '100%' },
  },
}

const defaultObserverSettings = {
  screenshotAllowed: true,
  titleAllowed: true,
  keystrokeAllowed: true,
  clipboardAllowed: true,
  urlLists: [],
  urlListValue: undefined,
  urlListAccessType: LIST_TYPES.NONE.val,
  applicationLists: [],
  applicationListValue: undefined,
  applicationListAccessType: LIST_TYPES.NONE.val,
  active: false,
}

const getIndex = selectedValue => AGGREGATION_STRATEGIES.findIndex(o => o.value === selectedValue)

const ObserverPrivacyTooltip = () => (
  <Fragment>
    <Paragraph style={{ marginTop: 0 }}>
      Enabling observer privacy changes the displayed observer-name from a combination of username-machine to a
      randomly-selected word, only for non-admin (Limited, Discovery, Power) role Process&nbsp;Discovery users.
    </Paragraph>
    <Paragraph>
      This optional setting is intended to help protect the privacy of your observed users as other people access their
      data in Process&nbsp;Discovery.
    </Paragraph>
    <Paragraph style={{ marginBottom: 0 }}>
      Enabling observer privacy also disables the ability to export data for non-admin users throughout the app.
      Exporting events to .csv will continue to display the username and machine-name for admin users.
    </Paragraph>
  </Fragment>
)

const CoEManagerRoleError = () => (
  <div>
    <p>
      The configured CoE Manager user does not have sufficient permissions. <br />
      The user must belong to at least a Collaborator role in CoE Manager.
    </p>
    <p>Are you sure you want to save your changes?</p>
  </div>
)

const CoEManagerAuthError = () => (
  <div>
    <p>The configured CoE Manager user or password is not valid.</p>
    <p>Are you sure you want to save your changes?</p>
  </div>
)

const DemiGroup = ({ children, className }) => (
  <Group
    className={className}
    gutter={get.numericValues('spacer').sm}
    justify='space-between'
    noOfCols={12}
    noEndGutter={true}
    span={6}
    style={{ margin: `${theme['default-spacer-md']} 0` }}
    type='flex'
  >
    {children}
  </Group>
)

const ManageAccount = ({ defaultTenant, tenantId, isLoading, setIsLoading, setDefaultTenant, allowAllSettings }) => {
  const [isTestable, setIsTestable] = useState()
  const [tenant, setTenant] = useState(defaultTenant)
  const [observerConfigLoading, setObserverConfigLoading] = useState(true)
  const { addNotification } = useNotification()
  const { roles } = useUserState()
  const { addModal, removeModal } = useModal()

  const [observerConfiguration, setObserverConfiguration] = useState(defaultObserverSettings)
  const {
    active,
    screenshotAllowed,
    titleAllowed,
    keystrokeAllowed,
    clipboardAllowed,
    urlLists,
    urlListValue,
    urlListAccessType,
    applicationLists,
    applicationListValue,
    applicationListAccessType,
  } = observerConfiguration

  // validate if COE field is empty or matches regex
  const isCoeUrlValid = !tenant.coeIntakeUrl || validCoeUrl.test(tenant.coeIntakeUrl)

  useEffect(() => {
    setTenant({ ...defaultTenant })
  }, [defaultTenant])
  useEffect(() => {
    const fetchConfigData = async () => {
      const {
        data: {
          data: { attributes: data },
        },
      } = await api.get('/tenants/neo_config_policies', { id: tenantId })

      const { urlLists: newUrlLists, applicationLists: newApplicationLists } = await fetchUrlAllowAndBlockLists()

      const { url: urlListValueObj, application: applicationListValueObj } = getConfiguredAllowBlockLists(
        data.allowBlockLists
      )

      const newUrlListAccessType = urlListValueObj?.accessType || LIST_TYPES.NONE.val

      const newAppListAccessType = applicationListValueObj?.accessType || LIST_TYPES.NONE.val

      const newValues = {
        ...data,
        urlLists: newUrlLists,
        urlListAccessType: newUrlListAccessType,
        urlListValue: urlListValueObj?.id,
        applicationLists: newApplicationLists,
        applicationListAccessType: newAppListAccessType,
        applicationListValue: applicationListValueObj?.id,
      }
      setObserverConfiguration(newValues)
      setObserverConfigLoading(false)
    }
    if (allowAllSettings) {
      fetchConfigData()
    } else {
      setObserverConfigLoading(false)
    }
  }, [allowAllSettings, tenantId])

  const onChange = ({ target: { name, value } }) => {
    // need to clone because set isn't making a new object
    setTenant({ ...set(tenant, name, value) })
    const emailFields = ['smtpHost', 'smtpPort', 'smtpUsername', 'smtpPassword', 'emailFromAddress']
    if (emailFields.includes(name)) {
      setIsTestable(false)
    }
  }

  const onChangePEG = ({ target: { name, value } }) => {
    const vals = { ...tenant }
    const nameArr = name.split('.')
    let i = 0
    let obj = vals
    while (i < nameArr.length - 1) {
      obj = obj[nameArr[i]]
      i += 1
    }
    obj[nameArr[i]] = value
    setTenant(vals)
  }

  const onObserverPrivacyChange = ({ selectedValue }) => {
    const settings = { ...tenant.privacySettings.settings }
    settings.observers.mode = selectedValue
    setTenant({ ...tenant, privacySettings: { settings } })
  }

  const onObserverActiveChange = ({ selectedValue }) =>
    setObserverConfiguration({ ...observerConfiguration, active: selectedValue })

  const isSubmitDisabled = () => {
    const urlListNotFilled = urlListAccessType !== LIST_TYPES.NONE.val && !urlListValue
    const applicationListNotFilled = applicationListAccessType !== LIST_TYPES.NONE.val && !applicationListValue
    const invalidEmptyPassword = tenant.coeUserName !== defaultTenant.coeUserName && !tenant.coeUserPassword
    return (
      urlListNotFilled ||
      applicationListNotFilled ||
      !isCoeUrlValid ||
      (!tenant.coeIntakeUrl && tenant.coeUserName) ||
      invalidEmptyPassword
    )
  }

  const save = async (forceCoeSave = false) => {
    // When a CoE user changes their username, the password must also change
    if (tenant.coeUserName && tenant.coeUserName !== defaultTenant.coeUserName && !tenant.coeUserPassword) return

    setIsLoading(true)
    const {
      aggregationFrequency,
      isActive,
      name,
      tenantType,
      smtpHost,
      smtpPort,
      smtpUsername,
      smtpPassword,
      emailFromAddress,
      appUrl,
      privacySettings,
      coeIntakeUrl,
      coeUserName,
      coeUserPassword,
    } = tenant

    if (appUrl && appUrl.endsWith('/')) {
      setTenant({ ...tenant, appUrl: appUrl.slice(0, -1) })
    }

    const params = {
      name: name,
      tenant_type: tenantType,
      app_url: appUrl,
      smtp_host: smtpHost,
      smtp_port: smtpPort,
      smtp_username: smtpUsername,
      smtp_password: smtpPassword,
      email_from_address: emailFromAddress,
      privacy_settings: privacySettings.settings,
      aggregation_frequency: aggregationFrequency,
      is_active: isActive || false,
      is_lite_customer: tenant.isLite || false,
      coe_intake_url: coeIntakeUrl || null,
      coe_user_name: coeUserName,
      coe_user_password: coeUserPassword,
      force_save: forceCoeSave || false,
    }

    // don't send secret_access_key if it's empty
    if (!params.privacy_settings.minio.secret_access_key) {
      delete params.privacy_settings.minio.secret_access_key
    }

    if (!params.smtp_password) {
      delete params.smtp_password
    }

    try {
      await api.put(`/tenants/${tenantId}`, params)
    } catch (error) {
      if (error?.response?.data?.error_type !== 'shibumi') return
      addModal({
        children:
          error?.response?.data?.error === 'Failed Permissions' ? <CoEManagerRoleError /> : <CoEManagerAuthError />,
        footer: (
          <Group>
            <Button
              onClick={() => {
                removeModal('coeManagerCrednetialsModal')
                setIsLoading(false)
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={() => {
                save(true)
                setIsLoading(false)
                removeModal('coeManagerCrednetialsModal')
              }}
              type='primary'
            >
              Save
            </Button>
          </Group>
        ),
        header: 'CoE Manager Credentials',
        id: 'coeManagerCrednetialsModal',
        style: { width: '500px', maxWidth: '90vw' },
      })
      return
    }

    if (allowAllSettings) {
      const defaultObserverConfig = {
        clipboardAllowed: clipboardAllowed || false,
        keystrokeAllowed: keystrokeAllowed || false,
        screenshotAllowed: screenshotAllowed || false,
        titleAllowed: titleAllowed || false,
      }

      const allowBlockLists = []
      if (urlListValue) allowBlockLists.push({ id: urlListValue, type: LIST_CATEGORIES.URL })
      if (applicationListValue) allowBlockLists.push({ id: applicationListValue, type: LIST_CATEGORIES.APPLICATION })

      await api.put(
        `/tenants/neo_config_policies?id=${tenantId}`,
        {
          ...defaultObserverConfig,
          allowBlockLists: allowBlockLists,
          active: active || false,
        },
        false
      )
    }

    setIsLoading(false)

    if (uiStore.tenant.id === tenant.id && tenant.isLite !== defaultTenant.isLite) {
      uiStore.setTenantData({ ...uiStore.tenant, isLite: tenant.isLite })
    }
    uiStore.setTenantData({ ...uiStore.tenant, coeIntakeUrl: coeIntakeUrl || null })

    addNotification({
      autoClose: 3000,
      description: 'Your tenant settings have been saved.',
      message: 'Updated Tenant',
      type: 'success',
    })

    setIsTestable(true)
    setDefaultTenant({ ...tenant })
  }

  const onSave = () => {
    if (tenant.isLite !== defaultTenant.isLite) {
      addModal({
        children: 'Turning the "FIQ Lite" option off will enable machine learning services for this tenant.',
        header: 'Confirm save',
        id: 'fiq-lite-confirm',
        style: { width: '400px' },
        onCancel: () => {
          // intentionally left blank
        },
        onOk: save,
      })
    } else {
      save()
    }
  }

  const onTest = async e => {
    e.preventDefault()
    const { data } = await api.put('/tenants/test')

    addNotification({
      autoClose: 3000,
      description: `A test email has been sent to ${data.email}.`,
      message: 'Email sent.',
      type: 'success',
    })
  }

  const selectOnChangeHandler = ({ name, value }) => {
    setTenant({ ...tenant, [name]: value })
  }

  const onSwitchFiqLite = ({ SWITCH_STATE }) => setTenant({ ...tenant, isLite: SWITCH_STATE })

  const onSwitch = ({ SWITCH_STATE }) =>
    onChangePEG({ target: { name: 'privacySettings.settings.minio.active', value: SWITCH_STATE } })

  if (isLoading || observerConfigLoading) {
    return <MainLoader />
  }

  const getTenantType = () => {
    const tenantTypeOption = TENANT_TYPES.find(type => type.value === tenant.tenantType)
    if (roles.includes('superuser') && !tenantTypeOption) return { label: '', value: null }
    if (!tenantTypeOption) return TENANT_TYPES[0]
    return tenantTypeOption
  }

  return (
    <Element style={containerCss}>
      <Element style={contentCss}>
        <Element style={columnCSS}>
          <Heading level={4}>General Settings</Heading>
          <DemiGroup>
            <Input label='Tenant name' name='name' onChange={onChange} value={tenant.name} {...sharedInputProps} />
            <Input
              label='UniqueID (read only)'
              onChange={onChange}
              readOnly={true}
              value={tenant.uuid}
              {...sharedInputProps}
            />
          </DemiGroup>
          {roles.includes('superuser') ? (
            <Can perform='/tenants:set-tenant-type'>
              <Label>Tenant Type</Label>
              <Select
                options={TENANT_TYPES}
                onChange={({ value }) => selectOnChangeHandler({ name: 'tenantType', value: value })}
                value={getTenantType()}
                style={{ marginBottom: theme['default-spacer-md'] }}
              />
            </Can>
          ) : (
            <React.Fragment>
              <Label>Tenant Type</Label>
              <Input
                value={getTenantType().value}
                disabled={true}
                style={{ marginBottom: theme['default-spacer-md'] }}
              />
            </React.Fragment>
          )}

          {defaultTenant.isLite && (
            <Can perform='/tenants:see-fiq-lite-toggle'>
              <SwitchSection
                tooltip={!roleActionCheck('/tenants:set-fiq-lite', roles) && 'Only Super Users may edit this.'}
                label='FIQ Lite'
                id='fiqLiteSwitch'
                checked={true}
                onSwitch={onSwitchFiqLite}
                disabled={!roleActionCheck('/tenants:set-fiq-lite', roles)}
              />
            </Can>
          )}
          <Input
            label='Application URL'
            name='appUrl'
            onChange={onChange}
            placeholder='https://example.automationanywhere.com'
            type='url'
            value={tenant.appUrl}
          />
          <Label htmlFor='aggregationFrequencySelect' {...selectLabelStyles}>
            Aggregation Strategy
          </Label>
          <Select
            defaultValue={AGGREGATION_STRATEGIES[getIndex(tenant?.aggregationFrequency)]}
            id='aggregationFrequencySelect'
            onChange={({ value }) => selectOnChangeHandler({ name: 'aggregationFrequency', value: value })}
            options={AGGREGATION_STRATEGIES}
            placeholder='Select an Aggregation Strategy...'
          />

          <SwitchSection
            label='Active Tenant'
            id='tenantIsActive'
            checked={tenant.isActive}
            onSwitch={({ SWITCH_STATE }) => onChange({ target: { name: 'isActive', value: SWITCH_STATE } })}
            style={{ marginBottom: theme['default-spacer-lg'] }}
          />

          <Heading level={4}>COE Manager Integration</Heading>
          <Label htmlFor='coeIntakeUrl' {...selectLabelStyles}>
            <div style={{ display: 'flex', alignContent: 'center', flexWrap: 'wrap' }}>
              COE Manager Opportunity Intake URL
              <InfoTooltip
                small={true}
                containerStyle={{ top: 'initial' }}
                title='This is the URL configured for the intake form for the CoE manager'
              />
            </div>
          </Label>
          <FormControl
            errors={((tenant.coeUserName && !tenant.coeIntakeUrl) || !isCoeUrlValid) && { message: 'Invalid URL' }}
            isRequired={false}
            placeholder='COE Manager Opportunity Intake URL...'
          >
            <Input name='coeIntakeUrl' onChange={onChange} type='url' value={tenant.coeIntakeUrl} />
          </FormControl>
          <DemiGroup>
            <FormControl
              errors={
                (tenant.coeIntakeUrl || tenant.coeUserPassword) &&
                !tenant.coeUserName && { message: 'This field is required.' }
              }
              isRequired={false}
              placeholder='COE Manager Opportunity Intake URL...'
            >
              <Input
                label='COE Manager Username'
                name='coeUserName'
                onChange={onChange}
                value={tenant.coeUserName}
                placeholder='COE Manager Username...'
                {...sharedInputProps}
              />
            </FormControl>
            {/* password will not be sent, but if a user has a coe user name, they have a passwword */}
            <FormControl
              errors={
                tenant.coeUserName &&
                tenant.coeUserName !== defaultTenant.coeUserName &&
                !tenant.coeUserPassword && { message: 'This field is required.' }
              }
              style={{ position: 'relative' }}
              errorProps={{ style: { position: 'absolute', marginLeft: 0, width: '100%' } }}
              isRequired={false}
              placeholder={defaultTenant.coeUserName ? '***************' : 'COE Manager Password...'}
            >
              <Input
                label='COE Manager Password'
                name='coeUserPassword'
                onChange={onChange}
                value={tenant.coeUserPassword}
                type='password'
                {...sharedInputProps}
              />
            </FormControl>
          </DemiGroup>

          <Heading level={4} style={{ marginTop: theme['default-spacer-lg'] }}>
            Mail Settings
          </Heading>
          <DemiGroup>
            <Input
              label='SMTP Host'
              name='smtpHost'
              onChange={onChange}
              placeholder='smtp.example.com'
              value={tenant.smtpHost}
              {...sharedInputProps}
            />
            <Input
              label='SMTP Port'
              name='smtpPort'
              onChange={onChange}
              placeholder='587'
              type='number'
              value={tenant.smtpPort}
              {...sharedInputProps}
            />
          </DemiGroup>
          <DemiGroup>
            <Input
              label='SMTP Username'
              name='smtpUsername'
              onChange={onChange}
              placeholder='username'
              value={tenant.smtpUsername}
              {...sharedInputProps}
            />
            <Input
              label='SMTP Password'
              name='smtpPassword'
              onChange={onChange}
              placeholder='password'
              type='password'
              value={tenant.smtpPassword}
              {...sharedInputProps}
            />
          </DemiGroup>
          <Input
            containerProps={fullRowInputContainerProps}
            label='Email From Address'
            name='emailFromAddress'
            onChange={onChange}
            placeholder='noreply@example.com'
            type='email'
            value={tenant.emailFromAddress}
            {...sharedInputProps}
          />
          <DemiGroup>
            <Button onClick={onTest} disabled={!isTestable || isLoading}>
              Send Test Email
            </Button>
          </DemiGroup>
        </Element>
        <Element style={columnCSS}>
          {allowAllSettings && (
            <Fragment>
              <Heading level={4}>Observer Settings</Heading>
              <Heading level={5} uppercase={true}>
                Default Observer Settings
                <InfoTooltip small={true} title='Default observer configuration for new tenant observers.' />
              </Heading>
              <Element style={observerConfigContainer}>
                <ConfigureObserver
                  screenshotAllowed={screenshotAllowed}
                  titleAllowed={titleAllowed}
                  keystrokeAllowed={keystrokeAllowed}
                  clipboardAllowed={clipboardAllowed}
                  urlLists={urlLists}
                  urlListValue={urlListValue}
                  urlListAccessType={urlListAccessType}
                  accessTypeOnChange={(key, type, value) =>
                    setObserverConfiguration({ ...observerConfiguration, [`${type}ListValue`]: null, [key]: value })
                  }
                  applicationLists={applicationLists}
                  applicationListValue={applicationListValue}
                  applicationListAccessType={applicationListAccessType}
                  onChange={(key, value) => setObserverConfiguration({ ...observerConfiguration, [key]: value })}
                  isLoading={observerConfigLoading}
                  styled={false}
                />
              </Element>
              <Row
                gutter={get.numericValues('spacer').lg}
                noOfCols={12}
                noEndGutter={true}
                type='grid'
                style={{ marginBottom: theme['default-spacer-sm'] }}
              >
                <Col span={5}>
                  <Heading level={5} style={{ whiteSpace: 'nowrap' }} uppercase={true}>
                    New Observer Recording
                    <InfoTooltip small={true} title='Enable or disable recording for new observers.' />
                  </Heading>
                  <RadioGroup
                    button={true}
                    label='Observers Record'
                    name='observerConfiguration.active'
                    onChange={onObserverActiveChange}
                    selectedValue={active}
                    style={{ marginBottom: theme['default-spacer-sm'] }}
                  >
                    <Radio value={true}>Enable</Radio>
                    <Radio value={false}>Disable</Radio>
                  </RadioGroup>
                </Col>
                <Col span={7}>
                  <Heading level={5} uppercase={true}>
                    Observer Privacy
                    <InfoTooltip small={false} title={<ObserverPrivacyTooltip />} />
                  </Heading>
                  <RadioGroup
                    button={true}
                    label='Observers Mode'
                    name='privacySettings.settings.observers.mode'
                    onChange={onObserverPrivacyChange}
                    selectedValue={tenant.privacySettings.settings.observers.mode}
                    style={{ marginBottom: theme['default-spacer-sm'] }}
                  >
                    <Radio value='default'>Standard Observer Names (Default)</Radio>
                    <Radio value='strict'>Privacy Mode</Radio>
                  </RadioGroup>
                </Col>
              </Row>
            </Fragment>
          )}
          <Heading level={4} style={{ marginTop: theme['default-spacer-md'] }}>
            PEG Settings
          </Heading>
          <DemiGroup>
            <Input
              label='Access Key ID'
              name='privacySettings.settings.minio.access_key_id'
              onChange={onChangePEG}
              value={tenant.privacySettings.settings.minio?.access_key_id}
              {...sharedInputProps}
            />
            <Input
              label='Secret Access Key'
              type='password'
              name='privacySettings.settings.minio.secret_access_key'
              onChange={onChangePEG}
              {...sharedInputProps}
            />
          </DemiGroup>
          <DemiGroup>
            <Input
              label='Endpoint'
              name='privacySettings.settings.minio.endpoint'
              onChange={onChangePEG}
              placeholder='https://endpointurl.com/'
              value={tenant.privacySettings.settings.minio?.endpoint}
              {...sharedInputProps}
            />
            <Input
              label='Bucket'
              name='privacySettings.settings.minio.bucket'
              onChange={onChangePEG}
              value={tenant.privacySettings.settings.minio?.bucket}
              {...sharedInputProps}
            />
          </DemiGroup>
          <DemiGroup>
            <Input
              label='Region'
              name='privacySettings.settings.minio.region'
              onChange={onChangePEG}
              value={tenant.privacySettings.settings.minio?.region}
              {...sharedInputProps}
            />
            <span />
          </DemiGroup>
          <SwitchSection
            label='Enable PEG'
            id='pegIsActive'
            checked={tenant.privacySettings.settings.minio?.active}
            onSwitch={onSwitch}
          />
        </Element>
      </Element>
      <Element style={footerCss}>
        <Button
          disabled={isSubmitDisabled()}
          loading={isLoading}
          onClick={onSave}
          style={{ marginLeft: 'auto' }}
          type='secondary'
        >
          Save
        </Button>
      </Element>
    </Element>
  )
}

ManageAccount.propTypes = {
  defaultTenant: shape({
    aggregationFrequency: string,
    appUrl: string,
    coeIntakeUrl: string,
    coeUserName: oneOfType([string, null]),
    createdAt: string,
    emailFromAddress: string,
    id: number,
    isActive: bool,
    isLite: bool,
    name: string,
    smtpHost: string,
    smtpPassword: string,
    smtpPort: string,
    smtpUsername: string,
    updatedAt: string,
    uuid: string,
  }),
  tenantId: oneOfType(string, number),
  isLoading: bool,
  setIsLoading: func,
  setDefaultTenant: func,
  allowAllSettings: bool,
}

export default ManageAccount
